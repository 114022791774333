<template>
  <div>
    <b-navbar fixed="top" active toggleable="lg" type="dark" variant="info">
      <b-avatar
        rounded="lg"
        variant="secondary"
        size="3rem"
        :src="imgSrc"
      ></b-avatar>
      <div class="profilenya">
        <span class="nama">{{ profile.nama }}</span>
        <span class="email">{{ profile.email }}</span>
      </div>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-navbar-nav>
          <b-nav-item>Jumlah Kantor</b-nav-item>
          <b-nav-item @click="$router.push('/features')">Features</b-nav-item>
          <b-nav-item @click="openModals">Sign Out</b-nav-item>
        </b-navbar-nav>
      </b-navbar-nav>
      <!-- </b-collapse> -->
    </b-navbar>

    <!-------------------------------------------------------- MODALS -------------------------------------------------------->
    <b-modal id="logout" hide-footer hide-header centered>
      <div
        class="title"
        style="
          font-family: 'nunito', sans-serif;
          font-size: 28px;
          font-weight: 700;
          text-transform: capitalize;
          text-align: center;
          margin-top: 4%;
        "
      >
        <span>Apakah Anda ingin melanjutkan <br />Sign Out</span>
      </div>

      <div
        class="button-wrapper"
        style="
          margin-top: 6%;
          margin-bottom: 2%;
          width: 100%;
          display: flex;
          justify-content: flex-end;
        "
      >
        <b-button
          @click="closeModals"
          style="
            font-family: 'nunito', sans-serif;
            font-weight: 700;
            text-transform: capitalize;
            background-color: #e0544e;
            border: none;
            margin-right: 4%;
          "
          >Tidak</b-button
        >
        <b-button
          @click="doLogout"
          style="
            font-family: 'nunito', sans-serif;
            font-weight: 700;
            text-transform: capitalize;
            background-color: #327ff2;
            border: none;
            margin-right: 4%;
            width: 20%;
          "
          >Ya</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ip_server } from "@/ip_config.js";

export default {
  data() {
    return {
      profile: "",
      imgSrc: "",
      loading: false,
      imgSrc: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    openModals() {
      this.$root.$emit("bv::show::modal", "logout");
    },

    closeModals() {
      this.$root.$emit("bv::hide::modal", "logout");
    },

    doLogout() {
      localStorage.setItem("token", "");
      this.$router.push("/");
    },

    goTo() {
      this.$router.push("/profile");
    },

    goDash() {
      this.$router.push("/main_dashboard");
    },

    async getData() {
      let vm = this;
      vm.loading = true;
      let x = localStorage.getItem("token");
      let profileQ = await axios.get(ip_server + "users/profile", {
        headers: {
          token: x,
        },
      });
      vm.profile = profileQ.data[0];
      vm.imgSrc = ip_server + vm.profile.foto;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
);
.navbar {
  background-color: map-get($color, "blue") !important;
  padding: 2%;
  .profilenya {
    margin-left: 1%;
    display: flex;
    flex-direction: column;
    .nama {
      font-family: "nunito", sans-serif;
      color: map-get($color, "white");
      font-size: 20px;
      font-weight: 700;
      text-transform: capitalize;
    }
    .email {
      font-family: "nunito", sans-serif;
      color: map-get($color, "white");
      font-size: 16px;
      font-weight: 300;
    }
  }
  ul {
    display: flex;
    justify-content: space-evenly;
    li {
      a {
        font-family: "nunito", sans-serif !important;
        color: map-get($color, "white") !important;
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
}
</style>