<template>
  <b-modal id="modal-delete" :title="`Hapus Fitur ${judul}`" centered>
    <p class="my-4">Apakah anda ingin menghapus fitur {{ judul }}?</p>

    <template #modal-footer>
      <b-button
        :disabled="busy"
        @click="$bvModal.hide('modal-delete')"
        variant="secondary"
        >Batal</b-button
      >
      <b-button @click="execute" variant="danger" :disabled="busy">{{
        pesan
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import { ip_server } from "@/ip_config";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    judul: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pesan: "Hapus",
      busy: false,
    };
  },
  methods: {
    async execute() {
      let vm = this;
      vm.pesan = "Mohon Tunggu";
      vm.busy = true;

      let delPost = await axios.post(
        ip_server + "fitur/delete",
        {
          id: this.id,
        },
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );
      if (delPost.data.message == "sukses") {
        vm.$bvModal.hide("modal-delete");
        vm.$emit("alertFromChild", {
          variant: "success",
          msg: `Berhasil Menghapus Fitur ${this.judul}`,
          showing: true,
        });
        vm.pesan = "Hapus";
        vm.busy = false;
      } else {
        vm.$bvModal.hide("modal-delete");
        vm.$emit("alertFromChild", {
          variant: "success",
          msg: `Terjadi Kesalahan Pada Server`,
          showing: true,
        });
        vm.pesan = "Hapus";
        vm.busy = false;
      }
    },
  },
};
</script>

<style>
</style>