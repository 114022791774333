<template>
  <b-container fluid>
    <b-row>
      <b-col style="padding: 0">
        <nav_bar />
      </b-col>
    </b-row>

    <b-row style="margin-top: 7.5%">
      <b-col cols="3" style="padding: 0">
        <div class="left">
          <div class="buttons">
            <b-button
              variant="outline-primary"
              @click="$router.push('/main_dashboard')"
              >Kembali</b-button
            >
            <b-button
              variant="primary"
              @click="
                (registerOrUpdate = true),
                  (editor = ''),
                  (judul = ''),
                  (id = '')
              "
              >Buat Fitur</b-button
            >
          </div>
          <div class="title">Daftar Fitur</div>
          <div class="list" v-for="item in list" :key="item.idx">
            <div class="judul" @click="getDetail(item.id)">
              {{ item.judulFitur }}
            </div>
            <b-button
              variant="danger"
              v-b-modal.modal-delete
              @click="(id = item.id), (judul = item.judulFitur)"
            >
              <img src="../../assets/icon/trash.svg" alt="" />
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col v-if="registerOrUpdate" cols="9">
        <b-overlay :show="overlay" rounded="sm">
          <div class="right">
            <div class="forms">
              <div class="button">
                <b-button
                  @click="(editor = ''), (judul = ''), (id = '')"
                  v-if="editor != '' || judul != ''"
                >
                  Hapus</b-button
                >
              </div>
              <b-form-group label="Judul Fitur">
                <b-form-input v-model="judul"></b-form-input>
              </b-form-group>
              <b-form-group label="Detail Fitur">
                <quill-editor
                  :options="editorOption"
                  v-model="editor"
                  style="min-height: 300px"
                />
              </b-form-group>
            </div>
            <div class="buttons-save">
              <center>
                <b-button @click="send" :disabled="loading">{{
                  text
                }}</b-button>
              </center>
            </div>
          </div>
        </b-overlay>
      </b-col>
      <b-alert class="alerts" :show="show" :variant="alert" dismissible fade>{{
        pesan
      }}</b-alert>
    </b-row>
    <ModalsDelete
      :judul="judul"
      :id="id"
      @alertFromChild="triggerAlert($event)"
    />
  </b-container>
</template>

<script>
import Nav_bar from "../../components/nav_bar.vue";
import { ip_server } from "@/ip_config";
import axios from "axios";
import ModalsDelete from "./modalsDelete.vue";

let toolOption = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["link", "image", "video"],
];

export default {
  components: {
    Nav_bar,
    ModalsDelete,
  },
  data() {
    return {
      editor: "",
      judul: "",
      loading: false,
      alert: "",
      show: false,
      overlay: false,
      registerOrUpdate: false,
      pesan: "",
      id: "",
      judul: "",
      list: [],
      text: "Simpan",
      editorOption: {
        modules: {
          toolbar: toolOption,
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    triggerAlert(event) {
      let vm = this;
      vm.alert = event.variant;
      vm.show = event.showing;
      vm.pesan = event.msg;
      vm.editor = "";
      vm.judul = "";
      vm.getData();
      setTimeout(() => {
        vm.show = false;
      }, 4000);
    },
    async send() {
      let vm = this;
      vm.loading = true;
      vm.text = "Mohon Tunggu";

      let formData = new FormData();
      formData.append("judulFitur", vm.judul);
      formData.append("detailFitur", vm.editor);

      if (vm.id) {
        this.update();
      } else {
        let send = await axios.post(ip_server + "fitur/register", formData, {
          headers: {
            token: localStorage.getItem("token"),
          },
        });
        if (send.data.message == "sukses") {
          vm.loading = false;
          vm.text = "Simpan";
          vm.pesan = "Berhasil menambahkan daftar fitur";
          vm.alert = "success";
          vm.editor = "";
          vm.judul = "";
          vm.show = true;
          setTimeout(() => {
            vm.show = false;
          }, 3000);
          this.getData();
        } else if (send.data.message == "gagal") {
          vm.loading = false;
          vm.text = "Simpan";
          vm.pesan = "Terjadi kesalahan";
          vm.alert = "danger";
          vm.show = true;
          setTimeout(() => {
            vm.show = false;
          }, 3000);
        }
      }
    },
    async update() {
      let vm = this;
      vm.text = "Mohon Tunggu";
      vm.loading = true;

      let formData = new FormData();
      formData.append("judulFitur", vm.judul);
      formData.append("detailFitur", vm.editor);
      formData.append("id", vm.id);

      let send = await axios.post(ip_server + "fitur/update", formData, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      if (send.data.message == "sukses") {
        vm.loading = false;
        vm.text = "Simpan";
        vm.pesan = "Berhasil mengubah isi fitur";
        vm.alert = "success";
        vm.editor = "";
        vm.judul = "";
        vm.id = "";
        vm.show = true;
        setTimeout(() => {
          vm.show = false;
        }, 3000);
        this.getData();
      } else if (send.data.message == "gagal") {
        vm.loading = false;
        vm.text = "Simpan";
        vm.pesan = "Terjadi kesalahan";
        vm.alert = "danger";
        vm.show = true;
        setTimeout(() => {
          vm.show = false;
        }, 3000);
      }
    },
    async getData() {
      let vm = this;

      let get = await axios.get(ip_server + "fitur/list", {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      vm.list = get.data.data;
    },
    async getDetail(id) {
      let vm = this;
      vm.overlay = true;
      vm.loading = true;
      vm.editor = "";

      let get = await axios.get(ip_server + "fitur/listById/" + id, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      vm.editor = get.data.data[0].detailFitur;
      vm.judul = get.data.data[0].judulFitur;
      vm.id = get.data.data[0].id;
      vm.registerOrUpdate = true;
      vm.overlay = false;
      vm.loading = false;
    },
  },
};
</script>

<style scoped>
.container-fluid {
  min-height: 100vh;
}
.left {
  border-right: 3px solid #327ff2;
  height: 85vh;
}
.left .buttons {
  padding: 2% 3%;
  display: flex;
  justify-content: space-between;
}
.left .title {
  font-size: 23px;
  padding: 1% 3%;
  font-weight: 600;
  color: #327ff2;
}
.left .list {
  padding: 3%;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 700;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right .forms {
  padding: 3%;
}
.right .forms .ql-toolbar {
  min-height: 300px;
  background-color: #327ff2;
}
.ql-editor {
  background-color: #327ff2;
}
.right .buttons-save {
  width: 100%;
  margin-top: 0.5%;
}
.right .buttons-save .btn {
  background-color: #327ff2;
  border: none;
  width: 30%;
}
.alerts {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  width: 60%;
  margin: auto;
}
.right .forms .button .btn {
  border: none;
  background-color: #327ff2;
  color: #fff;
  margin-bottom: 2%;
}
</style>